<template>
  <div>
    <div class="h-handle-bg">
      <div class="h-handle-button">
        <p class="h-return">
          <el-button type="text" size="small" @click="config.isDetailDisplay = false">返回</el-button>
        </p>
        <el-button type="primary" size="small" @click="addRule" v-right-code="'Ruleofmetadata.Add'">新增</el-button>
        <el-button type="primary" size="small" @click="saveRule" v-right-code="'Ruleofmetadata.Add'">保存</el-button>
      </div>
    </div>
    <el-tabs type="border-card" v-model="activeName" @tab-click="tab1Click">
      <el-tab-pane name="tabbasic">
        <span slot="label">基本信息</span>
        <div class="form-list">
          <el-form
            :model="dataSource"
            ref="_ruleForm"
            :rules="projectCheckRule"
          >
            <el-form-item>
              <el-col :span="4" class="form-title"><span style="color: red">*</span>规则名称：</el-col>
              <el-col :span="8">
                <el-form-item prop="RuleName">
                  <el-input
                    type="text" maxlength="50"
                    v-model="dataSource.RuleName"
                  ></el-input>
                </el-form-item>
              </el-col>
               
            </el-form-item>
               
           
          </el-form>
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-tabs type="border-card" v-model="tabDatasour">
        <el-tab-pane name="tabDatasour">
            <span slot="label">对象源</span>
                <div class="form-list">
          <el-form
            :model="dataSource"
          >
             <el-form-item>
              <el-col :span="4" class="form-title"
                ><span style="color: red">*</span>获取类型：</el-col
              >
              <el-col :span="8">
                <el-form-item prop="GainType">
                  <el-select
                    v-model="dataSource.GainType"
                    filterable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in gainTypeList"
                      :key="item.Value"
                      :label="item.Label"
                      :value="item.Value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>


              <el-col :span="4" class="form-title"
                ><span style="color: red">*</span>单据类型：</el-col
              >
              <el-col :span="8">
                <el-form-item prop="BillType">
                  <el-select
                    v-model="dataSource.BillType"
                    filterable
                    placeholder="请选择" @change="changeBillType"
                  >
                    <el-option
                      v-for="item in billTypeList"
                      :key="item.Value"
                      :label="item.Label"
                      :value="item.Value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
             
            </el-form-item>
            <el-form-item>
 <el-col :span="4" class="form-title"
                ><span style="color: red">*</span>单据部分：</el-col
              >
              <el-col :span="8">
                <el-form-item prop="BillBody">
                  <el-select
                    v-model="dataSource.BillBody"
                    filterable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in billBodyList"
                      :key="item.Value"
                      :label="item.Label"
                      :value="item.Value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
               <el-col :span="2" class="form-title"
                >&nbsp;</el-col>
              <el-col :span="4">
                <el-form-item prop="loadfields">
                    <el-button type="primary" size="small" @click="loadfields">加载字段</el-button>
                </el-form-item>
              </el-col>
            </el-form-item>
          </el-form>
                </div>
        </el-tab-pane>
    </el-tabs>

    <el-tabs
      type="border-card"
      v-model="activeNameConfig"
    >
      <el-tab-pane label="对象配置" name="first">
        <el-row>
          <el-col span="24">
            <el-table :data="dataSource.Detail">
              <el-table-column prop="sort" label="序号" width="55">
              </el-table-column>

    <el-table-column prop="ParamFieldName" label="物理字段" align="center" min-width="160" show-overflow-tooltip="true">
    <template slot-scope="scope">
      <el-input
        v-if="scope.row.isEdit"
        v-model="scope.row.ParamFieldName"
        placeholder="必填"
      ></el-input>
   
      <span v-else v-text="scope.row.ParamFieldName"></span>
    </template>
  </el-table-column>

   <el-table-column prop="ParamFieldTypeTag" label="数据类型" align="center" min-width="100" show-overflow-tooltip="true">
    <template slot-scope="scope">
      <el-select
       v-if="scope.row.isEdit"
                  v-model="scope.row.ParamFieldType"
                  filterable
                  placeholder="请选择" @change="updateParamFieldType(scope.row)"
                >
                  <el-option
                    v-for="item in paramFieldTypeList"
                    :key="item.Value"
                    :label="item.Label"
                    :value="item.Value"
                    
                  >
                  </el-option>
                </el-select>
   
      <span v-else v-text="scope.row.ParamFieldTypeTag"></span>
    </template>
  </el-table-column>

  <el-table-column prop="ParamFieldLabel" label="中文描述" align="center" min-width="160" show-overflow-tooltip="true">
    <template slot-scope="scope">
      <el-input
        v-if="scope.row.isEdit"
        v-model="scope.row.ParamFieldLabel"
      ></el-input>
   
      <span v-else v-text="scope.row.ParamFieldLabel"></span>
    </template>
  </el-table-column>
 
 <el-table-column prop="ParamFieldControlTag" label="控件类型" align="center" min-width="120">
    <template slot-scope="scope">
      <el-select
       v-if="scope.row.isEdit"
                  v-model="scope.row.ParamFieldControl"
                  filterable
                  placeholder="请选择" @change="updateParamFieldControl(scope.row)"
                >
                  <el-option
                    v-for="item in paramFieldControlList"
                    :key="item.Value"
                    :label="item.Label"
                    :value="item.Value"
                  >
                  </el-option>
                </el-select>
   
      <span v-else v-text="scope.row.ParamFieldControlTag"></span>
    </template>
  </el-table-column>
   <el-table-column prop="GroupName" label="分组名" align="center" min-width="100">
     <template slot-scope="scope">
      <el-input
        v-if="scope.row.isEdit"
        v-model="scope.row.GroupName"
      ></el-input>
   
      <span v-else v-text="scope.row.GroupName"></span>
    </template>
  </el-table-column>
              
             <el-table-column prop="OperatorTag" label="操作符" align="center" min-width="100">
    <template slot-scope="scope">
      <el-select
       v-if="scope.row.isEdit" 
                  v-model="scope.row.OperatorListSelect"
                  filterable
                  multiple 
                  placeholder="请选择" @change="updateOperator(scope.row)"
                >
                  <el-option
                    v-for="item in operatorList"
                    :key="item.Value"
                    :label="item.Label"
                    :value="item.Value"
                  >
                  </el-option>
                </el-select>
   
      <span v-else v-text="scope.row.OperatorTag"></span>
    </template>
  </el-table-column>
   <el-table-column prop="ParamFieldValueTypeTag" label="值类型" align="center" min-width="100">
    <template slot-scope="scope">
      <el-select
       v-if="scope.row.isEdit"
                  v-model="scope.row.ParamFieldValueType"
                  filterable
                  placeholder="请选择" @change="updateParamFieldValueType(scope.row)"
                >
                  <el-option
                    v-for="item in paramFieldValueTypeList"
                    :key="item.Value"
                    :label="item.Label"
                    :value="item.Value"
                  >
                  </el-option>
                </el-select>
   
      <span v-else v-text="scope.row.ParamFieldValueTypeTag"></span>
    </template>
  </el-table-column>

 
             <el-table-column prop="ParamFieldValue" width="300" label="值数据参数" align="center">
    <template slot-scope="scope">
      <el-input
        v-if="scope.row.isEdit && scope.row.ParamFieldValueType!=101"
        v-model="scope.row.ParamFieldValue"
         
      ></el-input>

       <el-select
       v-else-if="scope.row.isEdit && scope.row.ParamFieldValueType==101"
                  v-model="scope.row.ParamFieldValue"
                  filterable
                  placeholder="请选择" @change="updateDictionaryList(scope.row)"
                >
                  <el-option
                    v-for="item in DictionaryList"
                    :key="item.Value"
                    :label="item.Label"
                    :value="item.Value"
                  >
                  </el-option>
                </el-select>

                <span v-else-if="!scope.row.isEdit && scope.row.ParamFieldValueType==101" v-text="scope.row.ParamFieldValueTag"></span>
   
      <span v-else v-text="scope.row.ParamFieldValue"></span>
    </template>
  </el-table-column>

  <el-table-column prop="ParamFieldValue" label="参数取值字段" align="center" width="160">
    <template slot-scope="scope">
      <el-input
        v-if="scope.row.isEdit"
        v-model="scope.row.ValueColumn"
         
      ></el-input>
   
      <span v-else v-text="scope.row.ValueColumn"></span>
    </template>
  </el-table-column>

   <el-table-column prop="ParamFieldValue" width="200" label="列名" align="center">
    <template slot-scope="scope">
      <el-input
        v-if="scope.row.isEdit"
        v-model="scope.row.SelectColumns"
         
      ></el-input>
   
      <span v-else v-text="scope.row.SelectColumns"></span>
    </template>
  </el-table-column>
             
                 <el-table-column prop="DisplayTypeTag" label="展示类型" align="center" width="120">
    <template slot-scope="scope">
      <el-select
       v-if="scope.row.isEdit"
                  v-model="scope.row.DisplayType"
                  filterable
                  placeholder="请选择" @change="updateDisplayType(scope.row)"
                >
                  <el-option
                    v-for="item in displayTypelist"
                    :key="item.Value"
                    :label="item.Label"
                    :value="item.Value"
                  >
                  </el-option>
                </el-select>
   
      <span v-else v-text="scope.row.DisplayTypeTag"></span>
    </template>
  </el-table-column> 
             <el-table-column prop="ParamFieldPageTag" label="是否分页" align="center">
    <template slot-scope="scope">
      <el-select
       v-if="scope.row.isEdit"
                  v-model="scope.row.ParamFieldPage"
                  filterable
                  placeholder="请选择" @change="updateParamFieldPage(scope.row)"
                >
                  <el-option
                    v-for="item in boolList"
                    :key="item.Value"
                    :label="item.Label"
                    :value="item.Value"
                  >
                  </el-option>
                </el-select>
   
      <span v-else v-text="scope.row.ParamFieldPageTag"></span>
    </template>
  </el-table-column>

  <el-table-column prop="ParamFieldStatusTag" label="是否启用" align="center">
    <template slot-scope="scope">
      <el-select
       v-if="scope.row.isEdit"
                  v-model="scope.row.ParamFieldStatus"
                  filterable
                  placeholder="请选择" @change="updateParamFieldStatus(scope.row)"
                >
                  <el-option
                    v-for="item in boolList"
                    :key="item.Value"
                    :label="item.Label"
                    :value="item.Value"
                  >
                  </el-option>
                </el-select>
   
      <span v-else v-text="scope.row.ParamFieldStatusTag"></span>
    </template>
  </el-table-column>

              <el-table-column label="操作" :min-width="120">
                <template slot-scope="scope">
                  <el-button
                    @click="editData(scope.row)"
                    size="small"
                    type="text"
                    v-if="!scope.row.isEdit"
                    >编辑</el-button
                  >
                  <el-button
                    @click="endEditData(scope.row)"
                    size="small"
                    type="text"
                    v-if="scope.row.isEdit"
                    >完成</el-button
                  >
                  <el-button
                    @click="deleteData(scope.row)"
                    size="small"
                    type="text"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <!-- <div class="h-handle-button" center>
          <center>
            <el-button type="text" size="small" @click="addruledetail()"
              >新增</el-button
            >
          </center>
        </div> -->
      </el-tab-pane>
    </el-tabs>

    <DialogEx :options="showSelectConfig" title="选择列">
            <div>
                   <el-table :data="dataSource.DetailSelect" ref="multipleTable" max-height="300">
                      <el-table-column
    type="selection"
    width="55"
    :reserve-selection="true"
  > </el-table-column>
              <el-table-column prop="sort" label="序号" width="55">
              </el-table-column>

    <el-table-column prop="ParamFieldName" label="物理字段" align="center" width="200">
     <template slot-scope="scope">
      <span v-text="scope.row.ParamFieldName"></span>
    </template>
  </el-table-column>

  <el-table-column prop="ParamFieldLabel" label="中文描述" align="center">
    <template slot-scope="scope">
  
      <span v-text="scope.row.ParamFieldLabel"></span>
    </template>
  </el-table-column>
 
 </el-table>
 <br>
<center>
                    <el-col span="24">
                        <el-button type="primary" size="small" @click="onSaveSelectColumn">
                            保存
                        </el-button>
                       
                    </el-col>
</center>
 
 
            </div>
        </DialogEx>
  </div>
</template>
<script>
export default {
  data() {
    var _this = this;
    var checkRuleName = function (rule, value, callback) {
      if (!value) return callback(new Error("请输入规则名称"));
      callback();
    };
    
     
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      activeName: "tabbasic",
      activeNameConfig: "first",
      multipleSelection: [],
      isDisabled: false,
      projectCheckRule: {
        RuleName: [{ validator: checkRuleName, trigger: "blur" }],
      },
      addruledetailOptions: {
        visible: false,
        size: "small",
        width:"40%"
      },
      currentEnterpriseSelectedRow: {},
      //选择仓库
      warehouseConfig: {
        isDetailDisplay: true,
      },
      warehouseOptions: {
        visible: false,
        size: "small",
      },
      currentWarehouseSelectedRow: {},
      queryParam: {
        PageIndex: 1,
        PageSize: 10,
        //params: {
        //    NiceDate: '',
        //}
      },
      configdataSource: {
        ColDefs: {},
        Result: [],
        TotalCount: 0,
      },
      inputVisible: false,
      inputValue: "",
      projectList:[],
      gainTypeList:[],
      billTypeList:[],
      billBodyList:[],
      paramFieldTypeList:[],
      paramFieldControlList:[],
      operatorList:[],
      paramFieldValueTypeList:[],
      tabDatasour:'tabDatasour',
      boolList: [
        { Value: true, Label: "是" },
        { Value: false, Label: "否" },
      ],
      displayTypelist: [
        { Value: 1, Label: "动态参数" },
        { Value: 2, Label: "固定参数" },
      ],
       showSelectConfig: {
                    visible: false,
                    width: '40%',
                    fullscreen: false,
                    closeByModal: false,
                    showFooter: false,
                },
    };
  },
  props: {
    config: {
      isDetailDisplay: false,
    },
    dataSource: {},
  },
  computed: {},
  watch: {
    dataSource: {
      handler(curVal, oldVal) {
        //this.isDisabled = (this.Utils.emptyGuid == this.dataSource.WarehouseProjectID || this.dataSource.WarehouseProjectStatus == 100);
        this.isDisabled =
          this.Utils.emptyGuid != this.dataSource.WarehouseProjectID;
        //this.ProjectNo = this.dataSource.ProjectNo;
      },
      deep: true,
    },
  },

  mounted() {
    this.Event.$on("clearEditRuleForm", () => this.resetForm);
    this.initProjectList();
    this.initDictionaryList();
    this.initGainTypeList();
    this.initBillTypeList();
    this.initBillBodyList();
    this.initParamFieldTypeList();
    this.initParamFieldControlList();
    this.initOperatorList();
    this.initParamFieldValueTypeList();
    this.initDetail();
  },

  methods: {
    //选择客户
    onEnterpriseSelectedRow: function (row) {
      this.currentEnterpriseSelectedRow = row;
    },
    initProjectList:function()
    {
      return;
       var _this = this;
       _this.$ajax.send(
        "omsapi/ruleofmetadata/getprojectlist","get",{},(data) => {
          if (data.IsSuccess) {
            _this.projectList=data.Result;
          }
        }
      );
    },
    initGainTypeList:function()
    {
       var _this = this;
       _this.$ajax.send(
        "omsapi/ruleofmetadata/getgaintypelist","get",{},(data) => {
          if (data.IsSuccess) {
            _this.gainTypeList=data.Result;
          }
        }
      );
    },
    initDictionaryList:function()
    {
       var _this = this;
       _this.$ajax.send(
        "omsapi/base/getalllist","get",{},(data) => {
          if (data.IsSuccess) {
            _this.DictionaryList=data.Result;
          }
        }
      );
    },
    initBillTypeList:function()
    {
       var _this = this;
       _this.$ajax.send(
        "omsapi/ruleofmetadata/getbilltypelist","get",{},(data) => {
          if (data.IsSuccess) {
            _this.billTypeList=data.Result;
          }
        }
      );
    },
    initBillBodyList:function()
    {
       var _this = this;
       _this.$ajax.send(
        "omsapi/ruleofmetadata/getbillbodylist","get",{},(data) => {
          if (data.IsSuccess) {
            _this.billBodyList=data.Result;
          }
        }
      );
    },
    initDetail:function()
    {
     var _this=this;
      
     if(_this.dataSource.Detail!=null && _this.dataSource.Detail.length>0)
     {
         var index=0;
         for(var i=0;i<_this.dataSource.Detail.length;i++)
         {
             index++;
             _this.dataSource.Detail[i].sort = index;
             _this.dataSource.Detail[i].isEdit = false;
         }
     }
    },
    initParamFieldTypeList:function()
    {
        var _this = this;
       _this.$ajax.send(
        "omsapi/ruleofmetadata/getparamfieldtypelist","get",{},(data) => {
          if (data.IsSuccess) {
            _this.paramFieldTypeList=data.Result;
          }
        }
      );
    },
    initParamFieldControlList:function()
    {
         var _this = this;
       _this.$ajax.send(
        "omsapi/ruleofmetadata/getparamfieldcontrollist","get",{},(data) => {
          if (data.IsSuccess) {
            _this.paramFieldControlList=data.Result;
          }
        }
      );
    },
    initOperatorList:function()
    {
       var _this = this;
       _this.$ajax.send(
        "omsapi/ruleofmetadata/getoperatorlist","get",{},(data) => {
          if (data.IsSuccess) {
            _this.operatorList=data.Result;
          }
        }
      );
    },
    initParamFieldValueTypeList:function()
    {
       var _this = this;
       _this.$ajax.send(
        "omsapi/ruleofmetadata/getparamfieldvaluetypelist","get",{},(data) => {
          if (data.IsSuccess) {
            _this.paramFieldValueTypeList=data.Result;
          }
        }
      );
    },
    updateDictionaryList:function(obj)
    {
      var list=this.DictionaryList;
        for(var i=0;i<list.length;i++)
        {
            if(obj.ParamFieldValue==list[i].Value)
            {
                obj.ParamFieldValueTag=list[i].Label;
                break;
            }
        }
      
    },
    updateParamFieldType:function(obj)
    {
        var list=this.paramFieldTypeList;
        for(var i=0;i<list.length;i++)
        {
            if(obj.ParamFieldType==list[i].Value)
            {
                obj.ParamFieldTypeTag=list[i].Label;
                break;
            }
        }
        this.initDictionaryList();
    },updateDisplayType:function(obj)
    {
       var list=this.displayTypelist;
        for(var i=0;i<list.length;i++)
        {
            if(obj.DisplayType==list[i].Value)
            {
                obj.DisplayTypeTag=list[i].Label;
                break;
            }
        }
    },
    updateParamFieldControl:function(obj){
        var list=this.paramFieldControlList;
        for(var i=0;i<list.length;i++)
        {
            if(obj.ParamFieldControl==list[i].Value)
            {
                obj.ParamFieldControlTag=list[i].Label;
                break;
            }
        }
    },
    onSaveSelectColumn:function()
    {
      var _this=this;
      let arr = this.$refs.multipleTable.selection;

      if(arr==null || arr.length==0)
      {
         _this.Utils.messageBox("未选择数据", "error");
         return;
      }

      for(var a=0;a<arr.length;a++)
      {
        arr[a].sort=a+1;
      }

      if(_this.dataSource.Detail==null || _this.dataSource.Detail==undefined)
      {
        _this.dataSource.Detail=[];
      }

      for(var a=0;a<arr.length;a++)
      {
        _this.dataSource.Detail.push(arr[a]);
      }

      for(var a=0;a<_this.dataSource.Detail.length;a++)
      {
        _this.dataSource.Detail[a].sort=a+1;
      }

      _this.showSelectConfig.visible=false;
      _this.dataSource.DetailSelect=[];
    },
    updateOperator:function(obj){
         var list=this.operatorList;
         var strOperatorTag="";
         var strOperator="";

        if(obj.OperatorListSelect==null || obj.OperatorListSelect.length==0)
        {
            obj.OperatorTag="";
            return;
        }
        for(var j=0;j<obj.OperatorListSelect.length;j++)
        {
            for(var i=0;i<list.length;i++)
            {
                if(obj.OperatorListSelect[j]==list[i].Value)
                {
                    strOperatorTag+=list[i].Label+";";
                    strOperator+=list[i].Value+";";
                    break;
                }
            }
        }
        obj.OperatorTag=strOperatorTag;
        obj.Operator=strOperator;
    },
    updateParamFieldValueType:function(obj){
         var list=this.paramFieldValueTypeList;
        for(var i=0;i<list.length;i++)
        {
            if(obj.ParamFieldValueType==list[i].Value)
            {
                obj.ParamFieldValueTypeTag=list[i].Label;
                break;
            }
        }
    },
    updateParamFieldPage:function(obj){
        var list=this.boolList;
        for(var i=0;i<list.length;i++)
        {
            if(obj.ParamFieldPage==list[i].Value)
            {
                obj.ParamFieldPageTag=list[i].Label;
                break;
            }
        }
    },
    updateParamFieldStatus:function(obj){
        var list=this.boolList;
        for(var i=0;i<list.length;i++)
        {
            if(obj.ParamFieldStatus==list[i].Value)
            {
                obj.ParamFieldStatusTag=list[i].Label;
                break;
            }
        }
    },
    addRule:function()
    {
        this.Event.$emit("onAddRule");
    },
    saveRule:function()
    {
        var _this = this;
        _this.$refs["_ruleForm"].validate((valid) => {
        _this.isPassValidate = valid;
        if (valid) {
          if(_this.dataSource.Detail!=null && _this.dataSource.Detail.length>0)
          {
              //配置对象验证
              var detail=_this.dataSource.Detail;
              for(var i=0;i<detail.length;i++)
              {
                  if(detail[i].ParamFieldName==null || detail[i].ParamFieldName.length==0)
                  {
                      _this.Utils.messageBox("物理字段不能为空", "error");
                      return;
                  }
                  
                   if(detail[i].Operator==null || detail[i].Operator.length==0)
                  {
                      _this.Utils.messageBox("操作符不能为空", "error");
                      return;
                  }
              }
          }

          var routeName =
            (_this.dataSource.RuleID === "0" || _this.dataSource.RuleID === 0)
              ? "addruleofmetadata"
              : "editruleofmetadata";

          _this.$ajax.send(
            "omsapi/ruleofmetadata/" + routeName,
            "post",
            _this.dataSource,
            (data) => {
             
              if (data.IsSuccess) {
							_this.Utils.messageBox(data.OperationDesc, "success");
							_this.$parent.syncDataSource();
              _this.Event.$emit("reloadRuleList");
              _this.dataSource.Detail=[];
              _this.$refs["_ruleForm"].resetFields(); //清空表单
              _this.inputValue="";
              _this.dataSource.ProjectCode="";
              _this.dataSource.RuleName="";
						} else {
							_this.Utils.messageBox(data.OperationDesc, "error");
						}
            }
          );
        } else {
          return false;
        }
      });
    },
    addruledetail:function()
    {
       var _this = this;
       _this.$ajax.send(
        "omsapi/ruleofmetadata/getemptyruledetail","get",{},(data) => {
          if (data.IsSuccess) {
            var newruledetial=data.Result;
            if(_this.dataSource.Detail==undefined || _this.dataSource.Detail==null)
            {
                _this.dataSource.Detail=[];
            }

            for(var i=0;i<_this.dataSource.Detail.length;i++)
            {
                _this.dataSource.Detail[i].isEdit=false;
            }

            var index=_this.dataSource.Detail.length;
            index++;
            newruledetial.sort=index;
            newruledetial.isEdit=true;
            _this.dataSource.Detail.push(newruledetial);
          }
        }
      );
    },
    loadfields:function(){
       var _this = this;
       var obj={BillType:this.dataSource.BillType,
                BillBody:this.dataSource.BillBody};
       _this.$ajax.send(
        "omsapi/ruleofmetadata/getloadfields","get",obj,(data) => {
          if (data.IsSuccess) {
            _this.dataSource.DetailSelect=data.Result;
            _this.showSelectConfig.visible=true;
          }
        }
      );
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    deleteData: function (obj) {
        this.dataSource.Detail.splice(this.dataSource.Detail.indexOf(obj), 1);

        if(this.dataSource.Detail!=null && this.dataSource.Detail.length>0)
        {
          for(var a=0;a<this.dataSource.Detail.length;a++)
          {
            this.dataSource.Detail[a].sort=a+1;
          }
        }
    },
    tab1Click: function (tab, event) {},
    tab2Click: function (tab, event) {},
    editData:function(obj)
    {
        for(var i=0;i<this.dataSource.Detail.length;i++)
         {
            this.dataSource.Detail[i].isEdit = false;
         }
        obj.isEdit=true;

        var list=this.displayTypelist;
        for(var i=0;i<list.length;i++)
        {
            if(obj.DisplayType==list[i].Value)
            {
                obj.DisplayTypeTag=list[i].Label;
                break;
            }
        }
    },
    endEditData:function(obj)
    {
         for(var i=0;i<this.dataSource.Detail.length;i++)
         {
            this.dataSource.Detail[i].isEdit = false;
         }

        var list=this.displayTypelist;
        for(var i=0;i<list.length;i++)
        {
            if(obj.DisplayType==list[i].Value)
            {
                obj.DisplayTypeTag=list[i].Label;
                break;
            }
        }
        //obj.isEdit=false;
    },
    resetForm() {
      !this.isPassValidate && this.$refs["_ruleForm"].resetFields(); //清空表单
      this.dataSource.Detail=[];
    },
    reloadPageList: function () {
      this.initialize();
    },
    initialize() {
      this.onPageChange(this.queryParam);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.$emit("onCheckRow", this.multipleSelection);
    },
    changeBillType(){
      this.dataSource.Detail=[];
    },
    onPageChange(param) {
      var _this = this;
      this.queryParam = param;
      this.$ajax.query(
        "omsapi/logisticsProject/findList",
        "post",
        this.queryParam,
        (data) => {
          _this.dataSource = data;
        }
      );
    },
  },
  components: {
    //"Enterprise": resolve => { require(['../../../selector/enterpriseselector.vue'], resolve) },
    //"Warehouse": resolve => { require(['../../../selector/servicewarehouseselector.vue'], resolve) }
  },
};
</script>

<style>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
</style>